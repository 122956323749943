<template>
  <div>
    <v-container class="mb-8 pb-8 gradient">
      <v-row justify="center" class="mt-6 mb-6">
        <v-img
          src="/static/monplanning.svg"
          max-width="300"
          max-height="80"
          contain
        />
      </v-row>

      <v-row justify="center">
        <h1 class="text-h4 font-weight-medium text-center">Connexion à votre compte</h1>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10" lg="8" xl="6">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Accès sécurisé</v-toolbar-title>
              <v-spacer />
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information</v-icon>
                </template>
                <span
                  >Entrez vos informations de connexion pour accéder à vos
                  données.</span
                >
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="$route.query.resetpassword" type="success">{{
                $t("reset-password-confirmation")
              }}</v-alert>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('email', {
                          required: true,
                        })
                      "
                      v-model="user.email"
                      prepend-icon="mdi-email"
                      type="email"
                      name="email"
                      :rules="$helpers.rule.notEmpty()"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="user.password"
                      :label="
                        $helpers.string.capitalizeI18N('password', {
                          required: true,
                        })
                      "
                      prepend-icon="mdi-lock"
                      type="password"
                      name="password"
                      @keydown.enter="enterPressed()"
                      :rules="$helpers.rule.notEmpty()"
                    />
                  </v-row>
                  <v-row class="mt-8">
                    <v-btn
                      color="primary"
                      @click="handleLogin"
                      :disabled="loading"
                      block
                    >
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      <span>{{ $t("connection") }}</span>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col>
                    <div>
                      <v-btn
                        text
                        to="/admin/reset-password"
                        color="primary lighten-1"
                        >{{ $t("forgot-password") }}</v-btn
                      >
                    </div>
                    <div>
                      <v-btn
                        class="font-weight-medium"
                        text
                        to="/admin/register"
                        color="primary"
                        >Pas encore de compte ?<br
                          v-if="$helpers.display.isMobile"
                        />
                        Essayez 30 jours</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col class="text-right">
                    <!-- <v-btn
                      color="primary"
                      @click="handleLogin"
                      :disabled="loading"
                    >
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      <span class="d-none d-md-inline mr-2">{{
                        $t("login")
                      }}</span>
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-row>
                <!-- <v-row class="mt-6">
                  <v-divider />
                </v-row> -->
                <!-- <v-row class="mt-4" justify="center">
                <v-btn
                  @click="loginWithFacebook()"
                  depressed
                  outlined
                  height="50"
                  class="indigo lighten-1 white--text mt-6"
                  block
                  ><v-icon color="white" left> mdi-facebook </v-icon
                  >{{ $t("continue-with-facebook")
                  }}<v-icon color="white" right>
                    mdi-chevron-right
                  </v-icon></v-btn
                >
                <v-facebook-login-scope app-id="158795539016779" version="v9.0">
                </v-facebook-login-scope>
              </v-row> -->
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import User from "@/models/user";
// import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";

export default {
  name: "Login",
  components: {
    // VFacebookLoginScope,
  },
  data() {
    return {
      user: new User("", "", "", ""),
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.adminauth.user;
    },
  },
  created() {
    if (this.loggedIn) {
      console.dir("is logged !");
      this.$helpers.router.push("admin.dashboard");
    }
  },
  methods: {
    // loginWithFacebook() {
    //   window.FB.getLoginStatus((statusResponse) => {
    //     console.dir(statusResponse);
    //     if (statusResponse?.status !== "connected") {
    //       window.FB.login((response) => {
    //         console.dir(response);
    //         if (response?.status !== "connected") return;

    //         this.loginWithFacebookAccessToken(
    //           response.authResponse.accessToken
    //         );
    //       });
    //     } else {
    //       this.loginWithFacebookAccessToken(
    //         statusResponse.authResponse.accessToken
    //       );
    //     }
    //   });
    // },
    // loginWithFacebookAccessToken(accessToken) {
    //   this.$store.dispatch("adminauth/loginFacebook", accessToken).then(
    //     () => {
    //       this.$helpers.router.push("admin.dashboard");
    //     },
    //     (error) => {
    //       this.loading = false;

    //       this.$helpers.snackbar.handleError(error);
    //     }
    //   );
    // },
    enterPressed() {
      this.handleLogin();
    },
    handleLogin() {
      if (!this.$refs.form.validate()) return;

      if (this.user.email && this.user.password) {
        this.loading = true;

        this.$store.dispatch("adminauth/login", this.user).then(
          () => {
            this.$helpers.router.push("admin.dashboard");
          },
          (error) => {
            this.loading = false;

            this.$helpers.snackbar.handleError(error);
          }
        );
      }
      //});
    },
  },
};
</script>